import { createContext, useContext, useEffect, useState } from "react";
import { CompanyDoctor, Props } from "../@types";
import { ListResponse } from "../@types/employee";
import { useAuth } from "./useAuth";
import { usePreconfiguredFetch } from './useProvidePreconfiguredFetch';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

type CompanyDoctorContextType = {
    companyDoctors: CompanyDoctor[]
}

const CompanyDoctorContext = createContext<CompanyDoctorContextType>({
    companyDoctors: []
})

const useProvideCompanyDoctors = () => {
    const { user } = useAuth();
    const { fetchResource } = usePreconfiguredFetch();
    const [companyDoctors, setCompanyDoctors] = useState<CompanyDoctor[]>([]);

    useEffect(() => {
        (async () => {
            const storedCompanyDoctors = await localStorage.getItem('companyDoctors');

            if (!storedCompanyDoctors) {
                return;
            }
            const parsedCompanyDoctors = await JSON.parse(storedCompanyDoctors);

            await setCompanyDoctors(parsedCompanyDoctors);
        })()
    }, []);

    useEffect(() => {
        const getCompanyDoctors = async () => {
            if (!user || !user.accessToken || companyDoctors.length > 0) {
                return
            }
            const response = await fetchResource(`${API_ENDPOINT}/company-doctor/`);
            const data: ListResponse<CompanyDoctor> = await response.json()
            setCompanyDoctors([...data.content]);
        }

        getCompanyDoctors()
    }, [user])

    useEffect(() => {
        if (companyDoctors.length > 0) {
            localStorage.setItem('companyDoctors', JSON.stringify(companyDoctors));
        }
    }, [companyDoctors])

    return {
        companyDoctors
    }
}

const useCompanyDoctors = () => {
    return useContext(CompanyDoctorContext);
}

const ProvideCompanyDoctors = ({children}: Props) => {
    const companyDoctors = useProvideCompanyDoctors();

    return <CompanyDoctorContext.Provider value={companyDoctors}>
        {children}
    </CompanyDoctorContext.Provider>
}

export {useCompanyDoctors, ProvideCompanyDoctors}
export const enum Role {
    ADMIN = 'br_admin',
    BR_HR = 'br_hr',
    BR_HR_HELYETTES = 'br_hr_helyettes',
    UGYINTEZO = 'ugyintezo',
    VEZENYLO = 'vezenylo',
    KONYVELO = 'konyvelo',
}

export type User = {
    username: string;
    name: string;
    email: string;
    roles: [Role];
    groups: [string];
    accessToken: string | null;
    refreshToken: string | null;
    exp: number;
    refreshExp: number;
    hasRole: (role: Role) => boolean;
    isInGroup: (groupName: string) => boolean;
    isRefreshTimeExpired: () => boolean;
};

export type RealmAttribute = {
    roles: [Role];
};

export type AuthPayload = {
    preferred_username: string;
    name: string;
    email: string;
    iat: number;
    exp: number;
    family_name: string;
    given_name: string;
    groups?: [string];
    realm_access: RealmAttribute;
};

export type AuthContextType = {
    user: User | undefined;
    isAuthenticated: boolean;
    signOut: () => void;
    signIn: (accessToken: string, refreshToken: string) => void;
    setAuthToken: (token: string) => void;
    setRefreshToken: (token: string) => void;
    refreshTheToken: () => Promise<string |undefined>;
};

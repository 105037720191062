import { SecuredLayout } from '../../Layout/SecuredLayout';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Fade,
    Link,
    Modal,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useAuth, usePreconfiguredFetch, useSnackBar } from '../../../hooks';
import { DocumentListItem, EmployeeDocumentType } from '../../../@types';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { ArrowForwardIosTwoTone, CloudUploadOutlined } from '@mui/icons-material';
import { DropzoneArea } from 'mui-file-dropzone';
import { ContractDocumentType, FilterType, ListResponse } from '../../../@types/employee';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { getErrorMessage } from '../../../utils';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ConfirmCard } from '../../Layout/styles';
import { Role } from '../../../@types/auth';

interface LocationStateType {
    employeeName: string;
}

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: '#',
        width: 10,
    },
    {
        field: 'filename',
        headerName: 'Fájlnév',
        resizable: true,
        minWidth: 400,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
    },
    {
        field: 'type',
        headerName: 'Típus',
        width: 250,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        valueGetter: (params: GridValueGetterParams) => {
            const { type } = params.row;
            if (type === 'CONTRACT') {
                return 'Szerződés';
            }
            return EmployeeDocumentType[type as keyof typeof EmployeeDocumentType];
        },
    },
    {
        field: 'description',
        headerName: 'Megjegyzés',
        width: 150,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        valueGetter: (params: GridValueGetterParams) => {
            const { description } = params.row;
            const contractDocumentType = ContractDocumentType[description as keyof typeof ContractDocumentType];
            return contractDocumentType || description || '-';
        },
    },
    {
        field: 'uploadedAt',
        headerName: 'Feltöltve',
        width: 140,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        valueGetter: (params: GridValueGetterParams) => {
            const date = new Date(params.row.uploadedAt);
            return date.toLocaleDateString('hu-HU');
        },
    },
    {
        field: 'uploadedBy',
        headerName: 'Feltöltő',
        width: 110,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
    },
    {
        field: 'uuida',
        headerName: '',
        width: 120,
        sortable: false,
        editable: false,
        filterable: false,
        hideable: false,
        renderCell: (params: GridValueGetterParams) => (
            <Button
                sx={{ px: 2, borderRadius: 25, textDecoration: 'none' }}
                variant={'contained'}
                color={'error'}
                size={'small'}
                onClick={() => params.row.handleDelete()}
                endIcon={<DeleteForeverIcon />}>
                törlés
            </Button>
        ),
    },
    {
        field: 'uuid',
        headerName: '',
        width: 150,
        sortable: false,
        editable: false,
        filterable: false,
        hideable: false,
        renderCell: (params: GridValueGetterParams) => (
            <Link target={'_blank'} sx={{ textDecoration: 'none' }} href={params.row.getAuthenticatedLink()}>
                <Button
                    sx={{ px: 2, borderRadius: 25, textDecoration: 'none' }}
                    variant={'contained'}
                    color={'warning'}
                    size={'small'}
                    endIcon={<ArrowForwardIosTwoTone />}>
                    megnyitás
                </Button>
            </Link>
        ),
    },
];

const documentTypes: FilterType[] = [
    {
        value: '',
        label: 'Kérem válasszon',
    },
    ...Object.keys(EmployeeDocumentType).map(k => {
        return {
            value: k,
            label: EmployeeDocumentType[k as keyof typeof EmployeeDocumentType],
        };
    }),
];

const DocumentListPage = () => {
    const navigate = useNavigate();
    const { employeeId } = useParams();
    const location = useLocation();
    const state = location.state as LocationStateType;
    const { employeeName } = state;
    const { user } = useAuth();
    const { fetchResource } = usePreconfiguredFetch();
    const { snackBar, showError, showSuccess } = useSnackBar();

    const [documents, setDocuments] = useState<DocumentListItem[]>([]);
    const [documentType, setDocumentType] = useState<FilterType>(documentTypes[0]);
    const [otherDocumentType, setOtherDocumentType] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [uploadFile, setUploadFile] = useState<File | null>(null);
    const [fieldModified, setFieldModified] = useState<boolean>(false);

    const [documentToDelete, setDocumentToDelete] = useState<string>('');
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getAllDocuments = useCallback(async () => {
        const response = await fetchResource(`${API_ENDPOINT}/document/employee/${employeeId}`)

        if (!response.ok) {
            showError(
                <Typography paragraph p={0} m={0}>
                    {getErrorMessage(response)}
                </Typography>
            );
            return;
        }
        const data: ListResponse<DocumentListItem> = await response.json();
        setDocuments(data.content);
    }, [employeeId, user, showError]);

    useEffect(() => {
        getAllDocuments();
    }, []);

    const handleUploadDocument = async () => {
        if (!uploadFile || !user || !employeeId) {
            return;
        }

        if (!documentType.value) {
            showError(
                <Typography paragraph p={0} m={0}>
                    Hiányzó dokumentumtípus
                </Typography>
            );
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadFile);
        formData.append('description', description || '');
        formData.append('documentType', documentType.value);
        formData.append('otherDocumentType', otherDocumentType);
        formData.append('employeeId', employeeId);

        const response = await fetchResource(`${API_ENDPOINT}/document/employee`, {
            method: 'POST',
            body: formData
            });
        if (response.ok) {
            showSuccess(
                <Typography paragraph p={0} m={0}>
                    Sikeres feltöltés
                </Typography>
            );
            setUploadFile(null);
            setDescription('');
            setDocumentType(documentTypes[0]);
            setOtherDocumentType('');
            getAllDocuments();
            return;
        }
        showError(
            <Typography paragraph p={0} m={0}>
                Sikertelen feltöltés
            </Typography>
        );
    };

    const handleDeleteDocument = async () => {
        if (!documentToDelete || !user) {
            return;
        }
        setLoading(true);

        const response = await fetchResource(`${API_ENDPOINT}/document/employee/${documentToDelete}`, {
            method: 'DELETE'
        });
        if (!response.ok) {
            setLoading(false);
            showError(
                <Typography paragraph p={0} m={0}>
                    {getErrorMessage(response)}
                </Typography>
            );
            return;
        }
        setLoading(false);
        setDeleteModalOpen(false);
        setDocumentToDelete('');
        getAllDocuments();
    };

    if (!user?.hasRole(Role.BR_HR) && !user?.hasRole(Role.BR_HR_HELYETTES) && !user?.hasRole(Role.ADMIN)) {
        return null;
    }

    return (
        <SecuredLayout>
            <Grid container px={3} pt={1}>
                <Modal open={deleteModalOpen}>
                    <Fade in={deleteModalOpen}>
                        <ConfirmCard>
                            <CardContent sx={{ p: 2 }}>
                                <Typography variant={'h5'} pb={3}>
                                    Dokumentum törlés
                                </Typography>
                                <Typography paragraph>Tényleg törölni szeretné a dokumentumot? A törlés nem visszavonható.</Typography>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'space-between' }}>
                                <Button
                                    sx={{ mx: 1, borderRadius: 50 }}
                                    variant={'contained'}
                                    size={'small'}
                                    color={'info'}
                                    onClick={() => {
                                        setDocumentToDelete('');
                                        setDeleteModalOpen(false);
                                    }}
                                    startIcon={<ArrowBackIosNewTwoToneIcon />}>
                                    mégsem
                                </Button>
                                <Button
                                    sx={{ mx: 1, borderRadius: 50 }}
                                    variant={'contained'}
                                    size={'small'}
                                    color={'error'}
                                    onClick={handleDeleteDocument}
                                    endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <DeleteForeverIcon />}>
                                    törlés
                                </Button>
                            </CardActions>
                        </ConfirmCard>
                    </Fade>
                </Modal>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/'>
                            <GroupOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Állomány
                        </Link>
                        <Link
                            component={RouterLink}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color='inherit'
                            to={`/employee/${employeeId}/`}>
                            <PermIdentityOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            {employeeName}
                        </Link>
                        <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 'inherit' }} color='inherit'>
                            <CloudUploadOutlined sx={{ mr: 0.5 }} fontSize='inherit' />
                            Feltöltött dokumentumok
                        </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Box component='form'>
                <Grid container>
                    {snackBar()}
                    <Grid xs={12} lg={8}>
                        <Card variant={'elevation'} sx={{ backgroundColor: '#fff', px: 1, py: 3, m: 2 }}>
                            <Grid container px={3} pb={2} spacing={2}>
                                <Typography variant={'h4'}>Feltöltött dokumentumok</Typography>
                            </Grid>

                            <Stack sx={{ minHeight: '65vh', height: 'fit-content' }}>
                                <DataGrid
                                    columns={columns}
                                    rows={documents.map((d: DocumentListItem, index) => {
                                        return {
                                            ...d,
                                            id: index + 1,
                                            getAuthenticatedLink: () => `${API_ENDPOINT}${d.link}?token=${user?.accessToken}`,
                                            handleDelete: () => {
                                                setDocumentToDelete(d.uuid);
                                                setDeleteModalOpen(true);
                                            },
                                        };
                                    })}
                                    selectionModel={'none'}
                                    pageSize={30}
                                    rowsPerPageOptions={[30]}
                                />
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid xs={12} lg={4}>
                        <Card variant={'elevation'} sx={{ backgroundColor: '#eee', px: 1, py: 3, m: 2 }}>
                            <Grid container px={3} pb={2} spacing={2}>
                                <Typography variant={'h4'}>Feltöltés</Typography>
                            </Grid>
                            <Grid container px={3} pb={3} spacing={2}>
                                <Grid xs={12} pb={0}>
                                    <Autocomplete
                                        options={documentTypes}
                                        disableClearable
                                        onChange={(e, value) => {
                                            setDocumentType(value);
                                            setFieldModified(true);
                                        }}
                                        value={documentType}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                error={fieldModified && documentType.value === ''}
                                                variant='standard'
                                                label='Dokumentum típusa'
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12} pb={0}>
                                    {documentType.value === 'OTHER_DOCUMENT' && (
                                        <TextField
                                            fullWidth
                                            variant='standard'
                                            label={'Egyéb dokumentum típusa'}
                                            onChange={e => {
                                                setOtherDocumentType(e.target.value);
                                                setFieldModified(true);
                                            }}
                                            value={otherDocumentType}
                                        />
                                    )}
                                </Grid>
                                <Grid xs={12} pb={2}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'megjegyzés'}
                                        onChange={e => {
                                            setFieldModified(true);
                                            setDescription(e.target.value);
                                        }}
                                        value={description}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container px={3} pb={2} spacing={2}>
                                <DropzoneArea
                                    dropzoneClass={'dropzone-style'}
                                    dropzoneParagraphClass={'padding-nice'}
                                    fileObjects={uploadFile}
                                    onChange={file => setUploadFile(file[0])}
                                    onDelete={() => setUploadFile(null)}
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                                    showPreviews={true}
                                    maxFileSize={15000000}
                                    filesLimit={1}
                                    showAlerts={false}
                                    showPreviewsInDropzone={false}
                                    dropzoneText={'Dobd ide a fájlt vagy kattints a kiválasztásra!'}
                                />
                            </Grid>
                            <Grid container px={3} pb={2} spacing={2}>
                                <Grid xs={12} my={1}>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        fullWidth
                                        sx={{ borderRadius: 50 }}
                                        disabled={!uploadFile}
                                        startIcon={<BusinessOutlinedIcon />}
                                        onClick={() => handleUploadDocument()}>
                                        dokumentum feltöltése
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'end'} spacing={1} mx={1} pt={3} pb={2}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        color={'info'}
                        onClick={() => navigate(-1)}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        vissza
                    </Button>
                </Grid>
            </Box>
        </SecuredLayout>
    );
};

export { DocumentListPage };

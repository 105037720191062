import { Button, Card, CardActions, CardContent, Fade, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import { formatDateToYyyyMMdd, useAuth, useSnackBar } from '../../../utils';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { usePreconfiguredFetch } from '../../../hooks';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const style = {
    position: 'absolute' as 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

type ContractCloseModalProps = {
    contractId: string;
    open: boolean;
    onClose: () => void;
};

const ContractCloseModal: React.FC<ContractCloseModalProps> = ({ contractId, open, onClose }) => {
    const [toDate, setToDate] = useState<Date | null>(new Date());
    const { user } = useAuth();
    const { fetchResource } = usePreconfiguredFetch();
    const { showResponseError, showError, showSuccess, snackBar } = useSnackBar();

    const handleCloseContract = async () => {
        if (!toDate || !user) {
            onClose();
            return;
        }

        try {
            const response = await fetchResource(`${API_ENDPOINT}/contract/${contractId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    toDate: formatDateToYyyyMMdd(toDate),
                }),
            });
            if (response.status === 202) {
                showSuccess(
                    <Typography paragraph p={0} m={0}>
                        A szerződést sikeresen lezártuk
                    </Typography>
                );
                onClose();
                return;
            }

            await showResponseError(response);
        } catch (err: any) {
            showError(<Typography>{err.message}</Typography>);
            console.error(err);
        }
    };

    return (
        <>
            {snackBar()}
            <Modal open={open} closeAfterTransition>
                <Fade in={open}>
                    <Card sx={style}>
                        <CardContent>
                            <Typography gutterBottom variant='h5'>
                                Szerződés lezárása
                            </Typography>
                            <DesktopDatePicker
                                label='A szerződés záródátuma'
                                format='yyyy.MM.dd.'
                                value={toDate}
                                onChange={date => setToDate(date)}
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        fullWidth: true,
                                    },
                                }}
                            />
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'space-between' }}>
                            <Button variant={'contained'} size='small' color={'info'} onClick={() => onClose()}>
                                mégsem
                            </Button>
                            <Button variant={'contained'} size='small' color={'error'} onClick={handleCloseContract}>
                                szerződés lezárása
                            </Button>
                        </CardActions>
                    </Card>
                </Fade>
            </Modal>
        </>
    );
};

export { ContractCloseModal };

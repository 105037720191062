import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Breadcrumbs,
    Button,
    CircularProgress,
    Link,
    List,
    ListItem,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { EmployeeNotificationRequiredDetails, EmploymentType, JobTitle, PageableResponse } from '../../../@types';
import { SecuredLayout } from '../../Layout/SecuredLayout';
import { AddBox, ArrowForwardIosTwoTone } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { getEnumKeyByValue, getErrorMessage, useAuth, useJobTitles, useSnackBar } from '../../../utils';
import Grid from '@mui/material/Unstable_Grid2';
import {
    AwaitingNotificationStatisticsResponse,
    EmployeeListItem,
    EmployerCompany,
    FilterType
} from '../../../@types/employee';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import BadgeOutlined from '@mui/icons-material/BadgeOutlined';
import { NotifyEmployeeModal } from './NotifyEmployeeModal';
import { ColorMarkupHelper } from './ColorMarkupHelper';
import SendIcon from '@mui/icons-material/Send';
import { EmployeeCard } from './EmployeeCard';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import ViewListOutlined from '@mui/icons-material/ViewListOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { Role } from '../../../@types/auth';
import { NotifyGroupEmployeeModal } from './NotifyGroupEmployeeModal';
import { useEmployeeListSearchParams } from '../../../hooks/useEmployeeListSearchParams';
import { usePreconfiguredFetch } from '../../../hooks';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const EFO_API_ENDPOINT = process.env.REACT_APP_EFO_API_ENDPOINT;

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Név',
        width: 150,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
    },
    {
        field: 'jobTitle',
        headerName: 'Munkakör',
        width: 150,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
    },
    {
        field: 'phoneNumber',
        headerName: 'Telefonszám',
        width: 110,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
    },
    {
        field: 'email',
        headerName: 'E-mail cím',
        width: 250,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
    },
    {
        field: 'idCardNumber',
        headerName: 'Igazolványszám',
        width: 120,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
    },
    {
        field: 'medicalFitnessValidTo',
        headerName: 'Lejáró okmányok',
        width: 200,
        sortable: false,
        editable: false,
        filterable: false,
        hideable: false,
        renderCell: (params: GridValueGetterParams) => {
            const today = new Date();

            const medicalWarningDate = new Date(params.row.medicalFitnessValidTo);
            medicalWarningDate.setTime(medicalWarningDate.getTime() - 60 * 24 * 60 * 60 * 1000);
            const hasMedicalIssue =
                params.row.employmentType !== getEnumKeyByValue(EmploymentType, EmploymentType.SIMPLE_EMPLOYMENT) &&
                (params.row.medicalFitnessValidTo === null || medicalWarningDate.getTime() < today.getTime());

            const drivingLicenceWarningDate = new Date(params.row.drivingLicenceValidTo);
            drivingLicenceWarningDate.setTime(drivingLicenceWarningDate.getTime() - 30 * 24 * 60 * 60 * 1000);
            const hasDrivingLicenceIssue = params.row.drivingLicenceValidTo === null || drivingLicenceWarningDate.getTime() < today.getTime();

            const drivingLicenceQualificationWarningDate = new Date(params.row.drivingQualificationLicenceValidTo);
            drivingLicenceQualificationWarningDate.setTime(drivingLicenceQualificationWarningDate.getTime() - 30 * 24 * 60 * 60 * 1000);
            const hasDrivingQualificationLicenceIssue =
                params.row.drivingQualificationLicenceValidTo === null || drivingLicenceQualificationWarningDate.getTime() < today.getTime();

            const tachographCardWarningDate = new Date(params.row.tachographCardValidTo);
            tachographCardWarningDate.setTime(tachographCardWarningDate.getTime() - 30 * 24 * 60 * 60 * 1000);
            const hasTachographCardIssue = params.row.tachographCardValidTo === null || tachographCardWarningDate.getTime() < today.getTime();

            if (params.row.jobTitle !== 'autóbuszvezető') {
                return null;
            }

            const ListItemStyle = {
                fontSize: 10,
                lineHeight: '11px',
            };

            return (
                <List disablePadding>
                    {hasMedicalIssue ? (
                        <ListItem disablePadding sx={ListItemStyle}>{`Eü: ${params.row.medicalFitnessValidTo || 'NINCS MEGADVA'}`}</ListItem>
                    ) : null}
                    {hasDrivingLicenceIssue ? (
                        <ListItem disablePadding sx={ListItemStyle}>{`Jogosítvány: ${params.row.drivingLicenceValidTo || 'NINCS MEGADVA'}`}</ListItem>
                    ) : null}
                    {hasDrivingQualificationLicenceIssue ? (
                        <ListItem disablePadding sx={ListItemStyle}>{`GKI: ${params.row.drivingQualificationLicenceValidTo || 'NINCS MEGADVA'}`}</ListItem>
                    ) : null}
                    {hasTachographCardIssue ? (
                        <ListItem disablePadding sx={ListItemStyle}>{`Tachográf: ${params.row.tachographCardValidTo || 'NINCS MEGADVA'}`}</ListItem>
                    ) : null}
                </List>
            );
        },
    },
    {
        field: 'uuid',
        headerName: '',
        width: 150,
        sortable: false,
        editable: false,
        filterable: false,
        hideable: false,
        renderCell: (params: GridValueGetterParams) => (
            <Link sx={{ textDecoration: 'none' }} href={`/employee/${params.row.uuid}`}>
                <Button
                    sx={{ px: 2, borderRadius: 25, textDecoration: 'none' }}
                    variant={'contained'}
                    color={'warning'}
                    size={'small'}
                    endIcon={<ArrowForwardIosTwoTone />}>
                    Részletek
                </Button>
            </Link>
        ),
    },
];
const ConductorOptions: FilterType[] = [
    { value: '', label: 'mind' },
    { value: 'north', label: 'északi vezénylő' },
    { value: 'south', label: 'déli vezénylő' },
    { value: 'szeged', label: 'szegedi vezénylő' },
];
const EmploymentTypeOptions: FilterType[] = [
    { value: '', label: 'mind' },
    { value: 'permanent', label: 'állományos' },
    { value: 'simple_employment', label: 'egyszerű foglalkoztatás' },
    { value: 'contractor', label: 'külsős' },
];
const RetirementStatusOptions: FilterType[] = [
    { value: '', label: 'mind' },
    { value: 'active', label: 'aktív korú' },
    { value: 'retired', label: 'nyugdíjas' },
];
const ExpirationDocumentOptions: FilterType[] = [
    { value: '', label: 'mind' },
    { value: 'expiring', label: 'hamarosan lejáró' },
    { value: 'expired', label: 'lejárt' },
    { value: 'expiring|expired', label: 'lejáró és lejárt' },
];
const ExpiredDocumentTypeOptions: FilterType[] = [
    { value: '', label: 'nincs szűrés' },
    { value: 'medical_fitness_valid_to', label: 'Egészségügyi alkalmassági' },
    { value: 'id_card', label: 'Személyazonosító igazolvány' },
    { value: 'driving_licence_card', label: 'Jogosítvány' },
    { value: 'driver_qualification_licence_card', label: 'GKI kártya' },
    { value: 'tachograph_card', label: 'Tachográf kártya' },
];
const StatusOptions: FilterType[] = [
    { value: 'all', label: 'mind' },
    { value: 'active', label: 'aktív' },
    { value: 'inactive', label: 'inaktív' },
];

const DefaultAllJobTitles: JobTitle[] = [
    {
        name: 'minden munkakör',
        value: '',
    },
];

const FONT_SIZE = 14;

const EmployeeListPage = () => {
    const size = 500;
    const { user } = useAuth();
    const { fetchResource } = usePreconfiguredFetch()
    const { snackBar, showSuccess, showError } = useSnackBar();
    const { jobTitles } = useJobTitles();
    const navigator = useNavigate();
    const [search, setSearchParams] = useSearchParams();
    const { setEmployeeListSearchParams } = useEmployeeListSearchParams();
    const jobTitleSearchParam = search.get('jobtitle');
    const [isCardView, setIsCardView] = useState<boolean>(search.get('view_type') !== 'list');

    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [term, setTerm] = useState(search.get('term') || '');
    const [jobTitle, setJobTitle] = useState<JobTitle>(DefaultAllJobTitles[0]);
    const [conductor, setConductor] = useState<FilterType>(ConductorOptions.find(o => o.value === search.get('conductor')) || ConductorOptions[0]);
    const [employmentType, setEmploymentType] = useState<FilterType>(
        EmploymentTypeOptions.find(o => o.value === search.get('employment_type')) || EmploymentTypeOptions[0]
    );
    const [retired, setRetired] = useState<FilterType>(RetirementStatusOptions.find(o => o.value === search.get('retired')) || RetirementStatusOptions[0]);
    const [expiration, setExpiration] = useState<FilterType>(
        ExpirationDocumentOptions.find(o => o.value === search.get('expiration')) || ExpirationDocumentOptions[0]
    );
    const [expiredDocumentType, setExpiredDocumentType] = useState<FilterType>(
        ExpiredDocumentTypeOptions.find(o => o.value === search.get('expired_document_type')) || ExpiredDocumentTypeOptions[0]
    );
    const [status, setStatus] = useState<FilterType>(StatusOptions.find(o => o.value === search.get('status')) || StatusOptions[1]);
    const [employees, setEmployees] = useState(Array<EmployeeListItem>);
    const [selectedEmployee, setSelectedEmployee] = useState<EmployeeNotificationRequiredDetails>();
    const [notifyModalOpen, setNotifyModalOpen] = useState<boolean>(false);
    const [countOfUnsentWorkdays, setCountOfUnsentWorkdays] = useState<number>(0);
    const [countOfUnsentNotifications, setCountOfUnsentNotification] = useState<number>(0);
    const [roleDefinedUser, setRoleDefinedUser] = useState<boolean>(false);
    const [notifyGroupModalOpen, setNotifyGroupModalOpen] = useState<boolean>(false);
    const [checkedEmployees, setCheckedEmployees] = useState<EmployeeListItem[]>([]);

    const handleCheckedEmployee = (employee: EmployeeListItem, checked: boolean) => {
        if (checked) {
            setCheckedEmployees([...checkedEmployees, employee]);
        } else {
            setCheckedEmployees(checkedEmployees.filter(emp => emp.uuid !== employee.uuid));
        }
    };

    const updateSearchParams = (key: string, value: string) => {
        value ? search.set(key, value) : search.delete(key);
        setSearchParams(search);
        setEmployeeListSearchParams(search.toString());
    };

    useEffect(() => {
        const defaultSelectedJobTitle = jobTitles.find(o => o.value === 'autóbuszvezető');

        if (!defaultSelectedJobTitle) {
            return;
        }

        const jobTitleFromSearchParams = jobTitles.find(o => o.value === jobTitleSearchParam?.toLowerCase() || false);

        if ((user?.hasRole(Role.BR_HR) || user?.hasRole(Role.ADMIN)) && jobTitleFromSearchParams) {
            setJobTitle(jobTitleFromSearchParams);
            updateSearchParams('jobtitle', jobTitleFromSearchParams.value);
            return;
        }

        setJobTitle(defaultSelectedJobTitle);
        updateSearchParams('jobtitle', defaultSelectedJobTitle.value);
    }, [jobTitles]);

    useEffect(() => {
        if (
            !user ||
            !user.roles.find(
                r => r === Role.ADMIN || r === Role.BR_HR || r === Role.BR_HR_HELYETTES || r === Role.UGYINTEZO || r === Role.KONYVELO || r === Role.VEZENYLO
            )
        ) {
            window.location.replace('/login');
            return;
        }

        for (const option of ConductorOptions) {
            if (user.isInGroup(option.label)) {
                setConductor(option);
                updateSearchParams('conductor', option.value);
                break;
            }
        }

        getUnsentNotificationStatistics();
    }, []);

    const getEmployeeData = async () => {
        if (!user?.accessToken) {
            return;
        }

        setLoading(true);

        const params = new URLSearchParams(`?&size=${size}`);
        if (page > 0) {
            params.append('page', page.toString());
        }

        if (term.length > 0) {
            params.append('term', term);
        }

        if (jobTitle.id) {
            params.append('jobTitle', jobTitle.name);
        }

        if (conductor.value) {
            params.append('conductor', conductor.value);
        }

        if (retired.value !== '') {
            params.append('retired', retired.value);
        }

        if (expiration.value !== '') {
            params.append('expiration', expiration.value);
        }

        if (employmentType.value !== '') {
            params.append('employmentType', employmentType.value);
        }

        if (expiredDocumentType.value !== '') {
            params.append('expiredDocumentType', expiredDocumentType.value);
        }

        params.append('status', status.value);

        const response = await fetchResource(`${API_ENDPOINT}/employee/?${params.toString()}`);

        setLoading(false);

        if (!response.ok) {
            showError(
                <Typography paragraph p={0} m={0}>
                    {getErrorMessage(response)}
                </Typography>
            );
            return;
        }

        const data: PageableResponse<EmployeeListItem> = await response.json();

        setEmployees(data.content);
        setPage(data.pageable.pageNumber);
        setCount(data.totalElements);
    };

    const getUnsentNotificationStatistics = async () => {
        if (!user?.accessToken) {
            return;
        }
        const response = await fetchResource(`${EFO_API_ENDPOINT}/notification/awaiting`);
        if (!response.ok) {
            showError(
                <Typography paragraph p={0} m={0}>
                    {getErrorMessage(response)}
                </Typography>
            );
            return;
        }

        const data: AwaitingNotificationStatisticsResponse = await response.json();
        setCountOfUnsentNotification(data.countOfUnsentNotifications);
        setCountOfUnsentWorkdays(data.countOfUnsentWorkdays);
    };

    useEffect(() => {
        if (!loading && jobTitles && jobTitles.length > 0) {
            if (user?.hasRole(Role.BR_HR) || user?.hasRole(Role.ADMIN)) {
                getEmployeeData();
                return;
            }
            if (jobTitle === jobTitles.find(o => o.value === 'autóbuszvezető')) {
                getEmployeeData();
            }
        }
    }, [page, term, retired, conductor, expiration, expiredDocumentType, employmentType, jobTitle, status]);

    const handleEmailAddressClick = (email: string | undefined) => {
        if (!email) {
            return;
        }
        window.navigator.clipboard.writeText(email);
        showSuccess(<Typography paragraph pb={0} mb={0}>{`A(z) ${email} e-mail cím vágólapra másolva`}</Typography>);
    };

    const handleSendNotificationSummarieEmails = async () => {
        if (!user?.accessToken) {
            return;
        }
        const response = await fetchResource(`${EFO_API_ENDPOINT}/notification/send`);
        if (!response.ok) {
            showError(
                <Typography paragraph p={0} m={0}>
                    {getErrorMessage(response)}
                </Typography>
            );
            return;
        }
        showSuccess(<Typography paragraph pb={0} mb={0}>{`Az e-mailek kiküldését megkezdtük`}</Typography>);
        setCountOfUnsentNotification(0);
    };

    if (!jobTitles || jobTitles.length === 0) {
        return null;
    }

    return (
        <SecuredLayout>
            <NotifyEmployeeModal
                open={!!selectedEmployee && notifyModalOpen}
                isUnregister={false}
                employee={selectedEmployee}
                company={getEnumKeyByValue(EmployerCompany, EmployerCompany.PANNON_BUSZ_RENT) as keyof typeof EmployerCompany}
                date={new Date().getTime()}
                onClose={() => {
                    setNotifyModalOpen(false);
                    setSelectedEmployee(undefined);
                }}
            />
            <Grid container px={3} pt={1}>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/'>
                            <GroupOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Állomány
                        </Link>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container sx={{ height: '90vh', width: '100%' }} py={1} alignItems={'flex-start'}>
                {snackBar()}
                {(user?.hasRole(Role.BR_HR) || user?.hasRole(Role.BR_HR_HELYETTES)) && (
                    <Button
                        variant={'contained'}
                        color='primary'
                        aria-label='add'
                        sx={{ position: 'fixed', bottom: 25, right: 25, borderRadius: 50, zIndex: 1000 }}
                        onClick={() => navigator('/employee/create')}>
                        <AddBox sx={{ pr: 1 }} />
                        Új dolgozó
                    </Button>
                )}

                <Grid xs={12} lg={10} sx={{ height: '100%' }}>
                    {loading ? (
                        <Grid container justifyContent={'center'} alignItems={'center'} minHeight={'50%'}>
                            <CircularProgress color='inherit' />
                        </Grid>
                    ) : (
                        <Grid container sx={{ height: 'fit-content' }} px={2}>
                            {employees && employees.length ? (
                                !isCardView ? (
                                    <Grid xs={12} sx={{ height: '100%' }}>
                                        <Stack sx={{ height: '80vh' }}>
                                            <DataGrid
                                                columns={columns}
                                                rows={employees}
                                                pageSize={100}
                                                getRowId={emp => emp.uuid}
                                                rowsPerPageOptions={[100]}
                                                initialState={{
                                                    sorting: {
                                                        sortModel: [{ field: 'name', sort: 'asc' }],
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Grid>
                                ) : (
                                    employees.map(employee => (
                                        <Grid xs={12} sm={6} md={4} lg={3} xl={2} key={employee.uuid}>
                                            <EmployeeCard
                                                employee={employee}
                                                checkedEmployees={checkedEmployees}
                                                onEmployeeChange={(emp: EmployeeListItem, checked: boolean) => {
                                                    handleCheckedEmployee(emp, checked);
                                                }}
                                                onNotifyEmployee={(emp: EmployeeNotificationRequiredDetails) => {
                                                    setSelectedEmployee(emp);
                                                    setNotifyModalOpen(true);
                                                }}
                                                onHandleEmailAddressClick={address => handleEmailAddressClick(address)}
                                                user={user}
                                            />
                                        </Grid>
                                    ))
                                )
                            ) : (
                                <Grid xs={12} sx={{ justifyContent: 'center' }} p={3}>
                                    <Typography
                                        paragraph
                                        fontSize={FONT_SIZE}
                                        mb={0}
                                        textAlign={'center'}
                                        alignItems={'center'}
                                        sx={{ display: 'inline-block' }}>
                                        Nincs találat.
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Grid xs={12} lg={2}>
                    <Grid container px={2}>
                        <Grid xs={12} mb={2}>
                            <TextField
                                fullWidth
                                required
                                variant='standard'
                                label={'Keresés'}
                                onChange={e => {
                                    const term = e.target.value;
                                    setTerm(term);
                                    updateSearchParams('term', term);
                                }}
                                value={term}
                            />
                        </Grid>
                        <Grid xs={12} my={1}>
                            <Autocomplete
                                options={
                                    user?.hasRole(Role.BR_HR) || user?.hasRole(Role.ADMIN)
                                        ? [DefaultAllJobTitles[0], ...jobTitles]
                                        : jobTitles.filter(jt => jt.name === 'autóbuszvezető')
                                }
                                getOptionLabel={jobTitle => jobTitle.name}
                                disableClearable
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                disabled={roleDefinedUser}
                                onChange={(e, value) => {
                                    setJobTitle(value);
                                    updateSearchParams('jobtitle', value.value);
                                }}
                                value={jobTitle}
                                renderInput={params => <TextField {...params} variant='standard' placeholder='Összes munkakör' label='Munkakör' />}
                            />
                        </Grid>
                        <Grid xs={12} my={1}>
                            <Autocomplete
                                options={ConductorOptions}
                                disableClearable
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                disabled={roleDefinedUser}
                                onChange={(e, value) => {
                                    setConductor(value);
                                    updateSearchParams('conductor', value.value);
                                }}
                                value={conductor}
                                renderInput={params => <TextField {...params} variant='standard' label='Vezénylő' />}
                            />
                        </Grid>
                        <Grid xs={12} my={1}>
                            <Autocomplete
                                options={EmploymentTypeOptions}
                                disableClearable
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(e, value) => {
                                    setEmploymentType(value);
                                    updateSearchParams('employment_type', value.value);
                                }}
                                value={employmentType}
                                renderInput={params => <TextField {...params} variant='standard' label='Szerződés típusa' />}
                            />
                        </Grid>
                        <Grid xs={12} my={1}>
                            <Autocomplete
                                options={RetirementStatusOptions}
                                disableClearable
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(e, value) => {
                                    setRetired(value);
                                    updateSearchParams('retired', value.value);
                                }}
                                value={retired}
                                renderInput={params => <TextField {...params} variant='standard' label='Nyugdíj' />}
                            />
                        </Grid>
                        <Grid xs={12} my={1}>
                            <Autocomplete
                                options={ExpirationDocumentOptions}
                                disableClearable
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(e, value) => {
                                    setExpiration(value);
                                    updateSearchParams('expiration', value.value);
                                }}
                                value={expiration}
                                renderInput={params => <TextField {...params} variant='standard' label='Okmány lejárat' />}
                            />
                        </Grid>
                        <Grid xs={12} my={1}>
                            <Autocomplete
                                options={ExpiredDocumentTypeOptions}
                                disableClearable
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(e, value) => {
                                    setExpiredDocumentType(value);
                                    updateSearchParams('expired_document_type', value.value);
                                }}
                                value={expiredDocumentType}
                                renderInput={params => <TextField {...params} variant='standard' label='Lejárt okmány típusa' />}
                            />
                        </Grid>
                        <Grid xs={12} my={1}>
                            <Autocomplete
                                options={StatusOptions}
                                disableClearable
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(e, value) => {
                                    setStatus(value);
                                    updateSearchParams('status', value.value);
                                }}
                                value={status}
                                renderInput={params => <TextField {...params} variant='standard' label='Státusz' />}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Typography paragraph my={0}>
                                <small>{`Találatok: ${count} fő`}</small>
                            </Typography>
                        </Grid>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <Typography>
                                <ViewListOutlined fontSize={'large'} color={'action'} />
                            </Typography>
                            <Switch
                                checked={isCardView}
                                onChange={e => {
                                    const value = e.target.checked ? 'card' : '';
                                    setIsCardView(e.target.checked);
                                    updateSearchParams('view', value);
                                }}
                            />
                            <Typography>
                                <BadgeOutlined fontSize={'large'} color={'primary'} />
                            </Typography>
                        </Stack>

                        <Grid xs={12}></Grid>
                        {countOfUnsentNotifications === 0 ? null : (
                            <Grid xs={12} pt={4}>
                                <Button
                                    sx={{ px: 2, borderRadius: 25 }}
                                    variant={'contained'}
                                    color={'warning'}
                                    fullWidth
                                    onClick={() => handleSendNotificationSummarieEmails()}
                                    endIcon={<SendIcon />}>
                                    E-mailes bejelentések elküldése
                                    <br />
                                    {`(${countOfUnsentNotifications} bejelentés ${countOfUnsentWorkdays} munkanap)`}
                                </Button>
                            </Grid>
                        )}
                        <Grid xs={12} pt={2}>
                            <NotifyGroupEmployeeModal
                                open={notifyGroupModalOpen}
                                employees={checkedEmployees}
                                onClose={() => {
                                    setNotifyGroupModalOpen(false);
                                    setCheckedEmployees([]);
                                }}
                            />

                            {checkedEmployees.length > 0 && (
                                <Button
                                    variant={'contained'}
                                    color={'warning'}
                                    fullWidth
                                    sx={{ borderRadius: 50 }}
                                    onClick={() => {
                                        setNotifyGroupModalOpen(true);
                                    }}
                                    endIcon={<BusinessOutlinedIcon />}>
                                    Kijelöltek bejelentése
                                </Button>
                            )}
                        </Grid>
                        <Grid xs={12} pt={2}>
                            <ColorMarkupHelper />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SecuredLayout>
    );
};

export { EmployeeListPage };

import React, { createContext, useContext } from 'react';
import { useAuth } from '../utils';
import { Props } from '../@types';

type PreconfiguredFetchContextType = {
    fetchResource: (url: string, options?: RequestInit) => Promise<Response>;
}

const PreconfiguredFetchContext = createContext<PreconfiguredFetchContextType>({
    fetchResource: (url: string, optionsParams?: RequestInit) => Promise.resolve(new Response()),
})

const useProvidePreconfiguredFetch = () => {
    const { user, refreshTheToken, signOut } = useAuth();

    const fetchResource = async (url: string, optionsParams?: RequestInit): Promise<Response> => {
        const token = await refreshTheToken() || user?.accessToken;
        const options: RequestInit = {...optionsParams}

        const reqHeaders = new Headers(options.headers);

        reqHeaders.set('Authorization', `Bearer ${token}`);
        options.headers = reqHeaders;
        options.mode = 'cors';
        options.cache = 'no-cache';

        const response = await fetch(url, options);
        if (response.status === 401) {
            signOut();
            return Response.error()
        }
        return response;
    }

    return {
        fetchResource,
    }
}

const usePreconfiguredFetch = () => {
    return useContext(PreconfiguredFetchContext);
}

const ProvidePreconfiguredFetch = ({ children }: Props) => {
    const preconfiguredFetch = useProvidePreconfiguredFetch();
    return (<PreconfiguredFetchContext.Provider value={preconfiguredFetch}>{children}</PreconfiguredFetchContext.Provider>);
}

export { usePreconfiguredFetch, ProvidePreconfiguredFetch }
import { SecuredLayout } from '../../Layout/SecuredLayout';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Breadcrumbs, Button, Card, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useAuth, usePreconfiguredFetch, useSnackBar } from '../../../hooks';
import { Contract, EmploymentType, WageType } from '../../../@types';
import { getEnumKeyByValue, getErrorMessage } from '../../../utils';
import ArticleIcon from '@mui/icons-material/Article';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { ArrowForwardIosTwoTone, BlockOutlined, LibraryAddOutlined } from '@mui/icons-material';
import { ContractCloseModal } from '../../Employee/EmployeeDetails/ContractCloseModal';
import { ListResponse } from '../../../@types/employee';
import { Role } from '../../../@types/auth';

interface LocationStateType {
    employeeName: string;
}

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: '#',
        width: 50,
    },
    {
        field: 'origin',
        headerName: 'Típus',
        width: 80,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        headerAlign: 'right',
        align: 'right',
        valueGetter: (params: GridValueGetterParams) => {
            return !params.row.hasOrigin ? 'szerződés' : 'módosítás';
        },
    },
    {
        field: 'jobTitle',
        headerName: 'Munkakör',
        width: 150,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        headerAlign: 'right',
        align: 'right',
        valueGetter: (params: GridValueGetterParams) => params.row.jobTitle?.name || '-',
    },
    {
        field: 'employmentType',
        headerName: 'Szerződés típus',
        width: 150,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        headerAlign: 'right',
        align: 'right',
        valueGetter: (params: GridValueGetterParams) => EmploymentType[params.row.employmentType as keyof typeof EmploymentType],
    },
    {
        field: 'from',
        headerName: 'Kezdete',
        width: 110,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        headerAlign: 'right',
        align: 'right',
    },
    {
        field: 'to',
        headerName: 'Vége',
        width: 110,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridValueGetterParams) => params.row.to || 'folyamatban',
    },
    {
        field: 'wage',
        headerName: 'Munkabér',
        width: 110,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        headerAlign: 'right',
        align: 'right',
        valueGetter: (params: GridValueGetterParams) => {
            const wagePeriod =
                params.row.wageType === getEnumKeyByValue(WageType, WageType.MONTHLY)
                    ? 'hó'
                    : params.row.wageType === getEnumKeyByValue(WageType, WageType.DAILY)
                    ? 'nap'
                    : 'óra';
            return `${Number(params.row.wage).toLocaleString('hu-HU')} ft/${wagePeriod}`;
        },
    },
    {
        field: 'dailyWorkHours',
        headerName: 'Óraszám',
        width: 80,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        headerAlign: 'right',
        align: 'right',
    },
    {
        field: 'view',
        headerName: '',
        width: 150,
        sortable: false,
        editable: false,
        filterable: false,
        hideable: false,
        renderCell: (params: GridValueGetterParams) => (
            <Button
                sx={{ px: 2, borderRadius: 25 }}
                variant={'contained'}
                color={'warning'}
                size={'small'}
                onClick={() => params.row.navigateAction()}
                endIcon={<ArrowForwardIosTwoTone />}>
                részletek
            </Button>
        ),
    },
    {
        field: 'close',
        headerName: '',
        width: 150,
        sortable: false,
        editable: false,
        filterable: false,
        hideable: false,
        renderCell: (params: GridValueGetterParams) =>
            !params.row.to
            && params.row.closeable
            && params.row.hasRight ? (
                <Button
                    size={'small'}
                    sx={{ ml: 2, px: 2, borderRadius: 25 }}
                    onClick={() => params.row.closeContractAction()}
                    color={'error'}
                    variant={'contained'}
                    endIcon={<BlockOutlined />}>
                    lezárás
                </Button>
            ) : null,
    },
];

const ContractListPage = () => {
    const navigate = useNavigate();
    const { employeeId } = useParams();
    const location = useLocation();
    const state = location.state as LocationStateType;
    const { employeeName } = state;
    const { user } = useAuth();
    const { fetchResource } = usePreconfiguredFetch();
    const { snackBar, showError } = useSnackBar();
    const [isCloseContractModalOpen, setIsCloseContractModalOpen] = useState<boolean>(false);
    const [contractToClose, setContractToClose] = useState<string>('');
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [viewId, setViewId] = useState(Math.random());

    useEffect(() => {
        const getContracts = async () => {
            const response = await fetchResource(`${API_ENDPOINT}/employee/${employeeId}/contracts`);
            if (!response.ok) {
                showError(
                    <Typography paragraph p={0} m={0}>
                        {getErrorMessage(response)}
                    </Typography>
                );
                return;
            }
            const contracts: ListResponse<Contract> = await response.json();
            const flattedContractList: Contract[] = [];

            for (const contract of contracts.content) {
                flattedContractList.push(contract)
                let followUpContract = contract.followUpContract;
                if (!followUpContract) continue;
                while (followUpContract) {
                    flattedContractList.push(followUpContract);
                    followUpContract = followUpContract.followUpContract;
                }
            }
            setContracts(flattedContractList);
        };
        getContracts();
    }, [employeeId, viewId, showError, user?.accessToken]);

    return (
        <SecuredLayout>
            <Grid container px={3} pt={1}>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/'>
                            <GroupOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Állomány
                        </Link>
                        <Link
                            component={RouterLink}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color='inherit'
                            to={`/employee/${employeeId}/`}>
                            <PermIdentityOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            {employeeName}
                        </Link>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} color='inherit'>
                            <ArticleIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Szerződések
                        </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Box component='form'>
                {snackBar()}
                <ContractCloseModal
                    contractId={contractToClose}
                    open={isCloseContractModalOpen}
                    onClose={() => {
                        setIsCloseContractModalOpen(false);
                        setViewId(Math.random());
                    }}
                />
                <Grid container>
                    <Grid xs={12}>
                        <Card variant={'elevation'} sx={{ backgroundColor: '#fff', px: 1, py: 3, m: 2 }}>
                            <Grid container px={3} pb={2} spacing={2}>
                                <Typography variant={'h4'}>Szerződések</Typography>
                            </Grid>

                            <Stack sx={{ minHeight: '65vh', height: 'fit-content' }}>
                                <DataGrid
                                    columns={columns}
                                    rows={contracts.map((c: Contract, index) => {
                                        return {
                                            ...c,
                                            id: index + 1,
                                            navigateAction: () =>
                                                navigate(`/employee/${employeeId}/contract/${c.uuid}`, {
                                                    state: {
                                                        employeeName,
                                                        modifiable: !c.followUpContract
                                                    },
                                                }),
                                            closeContractAction: () => {
                                                setIsCloseContractModalOpen(true);
                                                setContractToClose(c.uuid);
                                            },
                                            closeable: !c.followUpContract,
                                            hasRight: user?.hasRole(Role.BR_HR) || user?.hasRole(Role.BR_HR_HELYETTES),
                                        };
                                    })}
                                    selectionModel={'none'}
                                    pageSize={30}
                                    rowsPerPageOptions={[30]}
                                />
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'end'} spacing={1} mx={1} pt={3} pb={2}>
                    {(user?.hasRole(Role.BR_HR) || user?.hasRole(Role.BR_HR_HELYETTES)) && (
                        <Button
                            sx={{ mx: 1, borderRadius: 50 }}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() =>
                                navigate(`/employee/${employeeId}/contract/create`, {
                                    state: {
                                        employeeName,
                                    },
                                })
                            }
                            endIcon={<LibraryAddOutlined />}>
                            új szerződés
                        </Button>
                    )}
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        color={'info'}
                        onClick={() => navigate(`/employee/${employeeId}`)}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        vissza
                    </Button>
                </Grid>
            </Box>
        </SecuredLayout>
    );
};

export { ContractListPage };

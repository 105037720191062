import { createContext, useContext, useEffect, useState } from "react";
import { JobTitle, ListResponse } from "../@types/employee";
import { useAuth } from "./useAuth";
import { Props } from "../@types";
import { usePreconfiguredFetch } from './useProvidePreconfiguredFetch';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

type JobTitleContextType = {
    jobTitles: JobTitle[];
}

const JobTitleContext = createContext<JobTitleContextType>({
    jobTitles: []
})

const useProvideJobTitles = () => {
    const { user } = useAuth();
    const { fetchResource } = usePreconfiguredFetch();
    const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);

    useEffect(() => {
        (async () => {
            const storedJobTitles = await localStorage.getItem('jobTitles');

            if (!storedJobTitles) {
                return;
            }
            const parsedJobTitles: JobTitle[] = await JSON.parse(storedJobTitles);
            
            await setJobTitles(parsedJobTitles.map(jt => {
                return {
                    ...jt,
                    value: jt.name
                }
            }));
        })();
    }, [])

    useEffect(() => {
        const fetchJobTitleList = async () => {
            const storedJobTitles = localStorage.getItem('jobTitles');
            if (!user?.accessToken || (storedJobTitles && storedJobTitles.length > 0)) {
                return;
            }
            const response = await fetchResource(`${API_ENDPOINT}/jobtitle/`)

            const data: ListResponse<JobTitle> = await response.json()
            setJobTitles([...data.content.map(jt => {
                return {
                    ...jt,
                    value: jt.name
                }
            })]);
        }
        fetchJobTitleList();
    }, [user]);

    useEffect(() => {
        if (jobTitles.length > 0) {
            localStorage.setItem('jobTitles', JSON.stringify(jobTitles));
        }
    }, [jobTitles])

    return {
        jobTitles
    }
}

const useJobTitles = () => {
    return useContext(JobTitleContext);
}

const ProvideJobTitles = ({children}: Props) => {
    const jobtitles = useProvideJobTitles();

    return (<JobTitleContext.Provider value={jobtitles}>
        {children}
    </JobTitleContext.Provider>);
}

export {useJobTitles, ProvideJobTitles}
import { SecuredLayout } from '../Layout/SecuredLayout';
import {
    Breadcrumbs,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import {
    formatDateToYyyyMMdd,
    getDaysBetweenDates,
    getErrorMessage,
    getLastDayOfMonth,
    useAuth,
    useSnackBar
} from '../../utils';
import { EmployeeListItem, EmployeeWorkday, EmployerCompany } from '../../@types/employee';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { PageableResponse } from '../../@types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { usePreconfiguredFetch } from '../../hooks';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const EFO_API_ENDPOINT = process.env.REACT_APP_EFO_API_ENDPOINT;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: '#BBB',
    },
}));

const EFOHistoryPage = () => {
    const today = new Date();
    const navigate = useNavigate();

    const { user } = useAuth();
    const { fetchResource } = usePreconfiguredFetch();
    const { showError } = useSnackBar();
    const [from, setFrom] = useState<Date>(new Date(`${today.getFullYear()}-${today.getMonth() + 1}-01`));
    const [to, setTo] = useState<Date>(new Date(getLastDayOfMonth(from)));
    const [selectedCompanies, setSelectedCompanies] = useState<(keyof typeof EmployerCompany)[]>(['PANNON_BUSZ_RENT']);
    const [employees, setEmployees] = useState<EmployeeListItem[]>([]);
    const [employeeWorkdays, setEmployeeWorkdays] = useState<EmployeeWorkday>();
    const [loading, setLoading] = useState<boolean>(false);

    const fetchEmployeeWorkdays = useMemo(async () => {
        if (!user?.accessToken) {
            return;
        }
        setLoading(true);
        const response = await fetchResource(
            `${EFO_API_ENDPOINT}/notification/?${new URLSearchParams({
                from: formatDateToYyyyMMdd(from),
                to: formatDateToYyyyMMdd(to),
                company: selectedCompanies.join(','),
            }).toString()}`);

        if (!response.ok) {
            showError(
                <Typography paragraph p={0} m={0}>
                    {getErrorMessage(response)}
                </Typography>
            );
            setLoading(false);
            return;
        }
        const data: EmployeeWorkday = await response.json();
        setLoading(false);
        return data;
    }, [from, to, selectedCompanies]);

    const fetchSimpleEmployedAndContractorEmployees = async () => {
        if (!user?.accessToken) return;
        const params = new URLSearchParams({
            size: '200',
            jobTitle: 'autóbuszvezető',
        });

        const response = await fetchResource(`${API_ENDPOINT}/employee/?${params.toString()}`);
        if (!response.ok) {
            showError(
                <Typography paragraph p={0} m={0}>
                    {getErrorMessage(response)}
                </Typography>
            );
            return;
        }

        const data: PageableResponse<EmployeeListItem> = await response.json();

        setEmployees(data.content);
    };

    useEffect(() => {
        fetchSimpleEmployedAndContractorEmployees();
    }, []);

    const getAllDays = useMemo(() => {
        return getDaysBetweenDates(from, to);
    }, [from, to]);

    useEffect(() => {
        (async () => setEmployeeWorkdays(await fetchEmployeeWorkdays))();
    }, [from, to]);

    useEffect(() => {
        const timeout = setTimeout(async () => {
            setEmployeeWorkdays(await fetchEmployeeWorkdays);
        }, 100);

        return () => clearTimeout(timeout);
    }, [selectedCompanies]);

    const getTableDayMarker = (employeeName: string, day: Date) => {
        if (!employeeWorkdays) {
            return '';
        }

        const workdayCompany = employeeWorkdays[employeeName].find(wdc => wdc.workday === formatDateToYyyyMMdd(day));

        if (workdayCompany === undefined) {
            return '';
        }

        return (
            <CheckCircleIcon color={workdayCompany.company === 'PANNON_BUSZ_RENT' ? 'primary' : workdayCompany.company === 'HR_MONTAGE' ? 'error' : 'info'} />
        );
    };

    return (
        <SecuredLayout>
            <Grid container px={3} pt={1} pb={4}>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/efo'>
                            <BusinessOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Egyszerűsített foglalkoztatások bejelentése
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid container sx={{ minHeight: '90vh', width: '100%' }} pb={2} alignItems={'flex-start'} justifyContent={'flex-end'}>
                    {Object.keys(EmployerCompany).map(c => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedCompanies.some(cmpny => cmpny === c)}
                                    onChange={e =>
                                        setSelectedCompanies(
                                            e.target.checked
                                                ? [...selectedCompanies, c as keyof typeof EmployerCompany]
                                                : selectedCompanies.filter(cmpny => cmpny !== c)
                                        )
                                    }
                                    name={EmployerCompany[c as keyof typeof EmployerCompany]}
                                    color={c === 'PANNON_BUSZ_RENT' ? 'primary' : c === 'HR_MONTAGE' ? 'error' : 'info'}
                                />
                            }
                            label={EmployerCompany[c as keyof typeof EmployerCompany]}
                            key={c}
                        />
                    ))}
                    <TableContainer component={Paper} sx={{ height: '86vh' }}>
                        <Table sx={{ minWidth: 700, minHeight: '90vh' }} size={'small'} stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ minWidth: '10rem', borderBottom: 'none' }} colSpan={getAllDays.length + 2} align={'center'}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <IconButton
                                                color={'primary'}
                                                onClick={() => {
                                                    const nextFrom = new Date(from);
                                                    nextFrom.setMonth(nextFrom.getMonth() - 1);
                                                    setFrom(nextFrom);
                                                    const nextTo = new Date(getLastDayOfMonth(nextFrom));
                                                    setTo(nextTo);
                                                }}
                                                sx={{ padding: 0.25 }}>
                                                <ArrowBackIosIcon />
                                            </IconButton>
                                            <Typography paragraph sx={{ display: 'inline-block', verticalAlign: 'center', p: 0, m: 0 }}>
                                                {from.toLocaleDateString('hu-HU', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                })}
                                            </Typography>
                                            <IconButton
                                                color={to.getTime() < today.getTime() ? 'primary' : 'default'}
                                                onClick={() => {
                                                    if (to.getTime() > today.getTime()) return;
                                                    const nextFrom = new Date(from);
                                                    nextFrom.setMonth(nextFrom.getMonth() + 1);
                                                    setFrom(nextFrom);
                                                    const nextTo = new Date(getLastDayOfMonth(nextFrom));
                                                    setTo(nextTo);
                                                }}
                                                sx={{ padding: 0.25 }}>
                                                <ArrowForwardIos />
                                            </IconButton>
                                        </div>
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell sx={{ minWidth: '6rem', padding: '1px 8px', borderBottom: 'none' }}>Munkavállaló</StyledTableCell>
                                    {getAllDays.map(d => (
                                        <StyledTableCell
                                            align='right'
                                            sx={{
                                                width: '0.8rem',
                                                textAlign: 'center',
                                                verticalAlign: 'center',
                                                borderBottom: 'none',
                                            }}
                                            key={d.getTime()}>
                                            {d.toLocaleString('hu-HU', {
                                                day: 'numeric',
                                            })}
                                        </StyledTableCell>
                                    ))}
                                    <StyledTableCell sx={{ width: '9rem', borderBottom: 'none' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loading ? (
                                    employeeWorkdays && Object.keys(employeeWorkdays).length > 0 ? (
                                        Object.keys(employeeWorkdays)
                                            .sort()
                                            .map(employeeName => (
                                                <StyledTableRow key={employeeName}>
                                                    <TableCell sx={{ padding: '1px 8px' }}>{employeeName}</TableCell>
                                                    {getAllDays.map(day => (
                                                        <TableCell padding={'none'} key={employeeName + day.getTime()} align={'center'}>
                                                            {getTableDayMarker(employeeName, day)}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell padding={'normal'}>
                                                        <Button
                                                            variant={'contained'}
                                                            color={'info'}
                                                            size={'small'}
                                                            sx={{ borderRadius: 50, width: '8rem' }}
                                                            endIcon={<ArrowForwardIos />}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                const employee = employees.find(e => e.legalName === employeeName);
                                                                if (!employee) return;
                                                                navigate(`/employee/${employee.uuid}/notification`, {
                                                                    state: {
                                                                        employeeId: employee.uuid,
                                                                        employeeName,
                                                                        taxNumber: employee.taxNumber,
                                                                        healthCareNumber: employee.healthCareNumber,
                                                                        employee,
                                                                    },
                                                                });
                                                            }}>
                                                            jelenléti ív
                                                        </Button>
                                                    </TableCell>
                                                </StyledTableRow>
                                            ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={getAllDays.length + 2} align={'center'}>
                                                <Typography>Nincs megjeleníthető adat</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={getAllDays.length + 2} align={'center'}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </SecuredLayout>
    );
};

export { EFOHistoryPage };

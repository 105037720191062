import { Button, CardActions, CardContent, CircularProgress, Grid, Modal, Typography } from '@mui/material';
import { ConfirmCard } from '../../Layout/styles';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import SaveIcon from '@mui/icons-material/Save';
import { usePreconfiguredFetch, useSnackBar } from '../../../hooks';
import { useState } from 'react';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

type KeycloakConfirmModalProps = {
    open: boolean;
    onClose: () => void;
    employeeId?: string;
    setKeycloakUsername: (param: string) => void;
    email: string;
};

export default function KeycloakConfirmModal({ open, onClose, employeeId, setKeycloakUsername, email }: KeycloakConfirmModalProps) {
    const { snackBar, showSuccess, showError, showResponseError } = useSnackBar();
    const [loading, setLoading] = useState<boolean>(false);
    const { fetchResource } = usePreconfiguredFetch();

    const fetchCreateKeycloakUser = async () => {
        if (!employeeId) {
            showError(<Typography>Hiányzó felhasználói azonosító!</Typography>);
            return;
        }
        setLoading(true);
        try {
            const response = await fetchResource(`${API_ENDPOINT}/keycloak-user/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ employeeId }),
            });
            if (response.status === 201) {
                showSuccess(<Typography>Sikeres mentés!</Typography>);
                setKeycloakUsername(email);
            } else if (response.status === 409) {
                showError(<Typography>A felhasználó már létezik!</Typography>);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(<Typography>Hiba történt az adat mentése közben: {error.message}</Typography>);
        }
        onClose();
        setLoading(false);
    };

    return (
        <>
            {snackBar()}
            <Modal open={open} onClose={onClose}>
                <ConfirmCard>
                    <CardContent sx={{ p: 2, maxWidth: '600px' }}>
                        <Grid container>
                            <Grid item xs={12} mb={2}>
                                <Typography variant='h5' align='left' gutterBottom>
                                    Keycloak felhasználó létrehozás
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography>
                            A létrehozás gombra kattintva a dolgozó aktuális e-mail címével, mint felhasználónév és TAJ számával, mint jelszó létrehoz egy
                            felhasználót a Keycloak-ban. Biztosan létrehozza ezt a felhasználót?
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'space-between' }}>
                        <Button
                            sx={{ mx: 1, borderRadius: 50 }}
                            variant={'contained'}
                            size={'small'}
                            color={'info'}
                            onClick={() => onClose()}
                            startIcon={<ArrowBackIosNewTwoToneIcon />}>
                            mégsem
                        </Button>
                        <Button
                            variant='contained'
                            size={'small'}
                            sx={{ borderRadius: 50, marginRight: 0.25 }}
                            onClick={() => fetchCreateKeycloakUser()}
                            endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <SaveIcon />}>
                            létrehozás
                        </Button>
                    </CardActions>
                </ConfirmCard>
            </Modal>
        </>
    );
}

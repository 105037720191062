import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { EFOStatistics, EmployerCompany } from '../../../@types';
import { CompanyStatisticType, ConsecutiveDaysType } from '../../../@types/employee';
import { usePreconfiguredFetch, useSnackBar } from '../../../hooks';
import { formatDateToYyyyMMdd, getErrorMessage } from '../../../utils';

const EFO_API_ENDPOINT = process.env.REACT_APP_EFO_API_ENDPOINT;

export type NotificationPropsType = {
    taxNumber: string;
    date: string;
};

const NotificationStatistics = ({ taxNumber, date }: NotificationPropsType) => {
    const today = new Date();
    const { fetchResource } = usePreconfiguredFetch();
    const { showError } = useSnackBar();

    const [statistics, setStatistics] = useState<CompanyStatisticType[]>([]);
    const [consecutiveDays, setConsecutiveDays] = useState<ConsecutiveDaysType>();
    const [loading, setLoading] = useState<boolean>(true);

    const getNotificationStatistics = async () => {
        try {
            setLoading(true);
            const params = new URLSearchParams();
            params.append('date', formatDateToYyyyMMdd(new Date(date)));

            const response = await fetchResource(`${EFO_API_ENDPOINT}/notification/statistics/${taxNumber}?${params.toString()}`);
            if (!response.ok) {
                showError(
                    <Typography paragraph p={0} m={0}>
                        {getErrorMessage(response)}
                    </Typography>
                );
                setLoading(false);
                return;
            }

            const data: EFOStatistics = await response.json();
            setStatistics(data.statistics);
            setConsecutiveDays(data.consecutiveDays);
            setLoading(false);
        } catch (e) {
            console.info(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        getNotificationStatistics();
    }, [date]);

    return (
        <Grid container p={0} pt={2} justifyContent={'center'}>
            {loading ? (
                <CircularProgress />
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%', minHeight: 200 }} size='small' aria-label='a dense table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Cég</TableCell>
                                <TableCell align='center'>{today.getFullYear()}</TableCell>
                                <TableCell align='center'>
                                    {today.toLocaleString('hu-HU', {
                                        month: 'long',
                                    })}
                                </TableCell>
                                <TableCell align='center'>egybefüggő</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(EmployerCompany).map(company => {
                                if (!statistics) {
                                    return null;
                                }
                                const companyStatistics = statistics.find(s => s.company === company);

                                return (
                                    <TableRow key={company} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{EmployerCompany[company as keyof typeof EmployerCompany]}</TableCell>
                                        <TableCell align='center'>90/{companyStatistics?.yearly || 0}</TableCell>
                                        <TableCell align='center'>15/{companyStatistics?.monthly || 0}</TableCell>
                                        <TableCell align='center'>
                                            5/{consecutiveDays && consecutiveDays.company === company ? consecutiveDays.count : 0}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Grid>
    );
};

export { NotificationStatistics };

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { Card as PersonalCard, CardType, ConductorType, Employee, EmploymentType } from '../../../@types';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { InputField } from './validation';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { CloudUploadOutlined, LibraryAddOutlined } from '@mui/icons-material';
import { SecuredLayout } from '../../Layout/SecuredLayout';
import {
    fieldValidator,
    formatDateToYyyyMMdd,
    getEnumKeyByValue,
    getErrorMessage,
    useAuth,
    useCompanyDoctors,
    useSnackBar
} from '../../../utils';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { StatusType } from '../../../@types/employee';
import ArticleIcon from '@mui/icons-material/Article';
import WarningModal from './WarningModal';
import { Role } from '../../../@types/auth';
import { DriverClassification } from '../../../@types/document';
import KeyIcon from '@mui/icons-material/Key';
import KeycloakConfirmModal from './KeycloakConfirmModal';
import { useEmployeeListSearchParams } from '../../../hooks/useEmployeeListSearchParams';
import { usePreconfiguredFetch } from '../../../hooks';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const EmployeeDetailsPage = () => {
    const today = new Date();
    const navigate = useNavigate();
    const { employeeId } = useParams();
    const { snackBar, showError } = useSnackBar();
    const { user } = useAuth();
    const { fetchResource } = usePreconfiguredFetch();
    const { companyDoctors } = useCompanyDoctors();
    const navigator = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nickName, setNickName] = useState('');
    const [birthName, setBirthName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [temporaryAddress, setTemporaryAddress] = useState('');
    const [siteAddress, setSiteAddress] = useState<string>('');
    const [idCardNumber, setIdCardNumber] = useState('');
    const [idCardValidTo, setIdCardValidTo] = useState<Date | null>(null);
    const [addressCardNumber, setAddressCardNumber] = useState('');
    const [drivingLicenceNumber, setDrivingLicenceNumber] = useState('');
    const [drivingLicenceNumberValidTo, setDrivingLicenceNumberValidTo] = useState<Date | null>(null);
    const [drivingQualificationLicenceNumber, setDrivingQualificationLicenceNumber] = useState('');
    const [drivingQualificationLicenceNumberValidTo, setDrivingQualificationLicenceNumberValidTo] = useState<Date | null>(null);
    const [tachographCardNumber, setTachographCardNumber] = useState('');
    const [tachographCardNumberValidTo, setTachographCardNumberValidTo] = useState<Date | null>(null);
    const [mothersName, setMothersName] = useState('');
    const [taxNumber, setTaxNumber] = useState('');
    const [healthCareNumber, setHealthCareNumber] = useState('');
    const [medicalFitnessValidTo, setMedicalFitnessValidTo] = useState<Date | null>(null);
    const [hasAnnuity, setHasAnnuity] = useState<boolean>(false);
    const [retired, setRetired] = useState<boolean>(false);
    const [status, setStatus] = useState<keyof typeof StatusType>(getEnumKeyByValue(StatusType, StatusType.ACTIVE) as keyof typeof StatusType);
    const [conductors, setConductors] = useState<ConductorType[]>([]);
    const [employmentType, setEmploymentType] = useState<keyof typeof EmploymentType>(
        getEnumKeyByValue(EmploymentType, EmploymentType.PERMANENT) as keyof typeof EmploymentType
    );
    const [companyDoctor, setCompanyDoctor] = useState<string | null>(null);
    const [bankAccount, setBankAccount] = useState<string>('');
    const [version, setVersion] = useState<number>(0);
    const [originalEmploymentType, setOriginalEmploymentType] = useState<keyof typeof EmploymentType>(
        getEnumKeyByValue(EmploymentType, EmploymentType.PERMANENT) as keyof typeof EmploymentType
    );
    const [isBusDriver, setIsBusDriver] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [employee, setEmployee] = useState<Employee | undefined>();
    const [iButton, setIButton] = useState<string>('');
    const [classification, setClassification] = useState<keyof typeof DriverClassification>('BUS_LOCAL');
    const [keycloakUsername, setKeycloakUsername] = useState<string>('');
    const [keycloakModalOpen, setKeycloakModalOpen] = useState<boolean>(false);
    const { employeeListSearch } = useEmployeeListSearchParams();

    useEffect(() => {
        const getEmployeeData = async () => {
            setLoading(true);
            const response = await fetchResource(`${API_ENDPOINT}/employee/${employeeId}`);

            if (!response.ok) {
                if (response.status === 403) {
                    navigator('/employee');
                    return;
                }
                showError(
                    <Typography paragraph p={0} m={0}>
                        {getErrorMessage(response)}
                    </Typography>
                );
                setLoading(false);
                return;
            }
            const data: Employee = await response.json();
            setEmployee(data);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setNickName(data.nickName || '');
            setBirthName(data.birthName);
            setEmail(data.email || '');
            setPhoneNumber(data.phoneNumber || '');
            setPlaceOfBirth(data.placeOfBirth || '');
            setDateOfBirth(data.dateOfBirth ? new Date(data.dateOfBirth) : null);
            setMedicalFitnessValidTo(data.medicalFitnessValidTo ? new Date(data.medicalFitnessValidTo) : null);
            setCompanyDoctor(data.companyDoctor?.name || null);
            setPermanentAddress(data.permanentAddress || '');
            setTemporaryAddress(data.temporaryAddress || '');
            setMothersName(data.mothersName || '');
            setHealthCareNumber(data.healthCareNumber || '');
            setTaxNumber(data.taxNumber || '');
            setIButton(data.driverAttribute?.ibutton || '');
            setSiteAddress(data.driverAttribute?.siteAddress || '');

            const idCard = data.cards.find(card => card.type === 'ID_CARD');
            setIdCardNumber(idCard?.number || '');
            setIdCardValidTo(idCard?.validTo ? new Date(idCard.validTo) : null);

            const addressCard = data.cards.find(card => card.type === 'ADDRESS_CARD');
            setAddressCardNumber(addressCard?.number || '');

            const drivingLicenceCard = data.cards.find(card => card.type === 'DRIVING_LICENCE_CARD');
            setDrivingLicenceNumber(drivingLicenceCard?.number || '');
            setDrivingLicenceNumberValidTo(drivingLicenceCard?.validTo ? new Date(drivingLicenceCard.validTo) : null);

            const drivingQualificationLicenceCard = data.cards.find(card => card.type === 'DRIVER_QUALIFICATION_LICENCE_CARD');
            setDrivingQualificationLicenceNumber(drivingQualificationLicenceCard?.number || '');
            setDrivingQualificationLicenceNumberValidTo(drivingQualificationLicenceCard?.validTo ? new Date(drivingQualificationLicenceCard.validTo) : null);

            const tachographCard = data.cards.find(card => card.type === 'TACHOGRAPH_CARD');
            setTachographCardNumber(tachographCard?.number || '');
            setTachographCardNumberValidTo(tachographCard?.validTo ? new Date(tachographCard.validTo) : null);
            setConductors(data.conductors.map(c => c as ConductorType));
            setRetired(data.retired);
            setHasAnnuity(data.hasAnnuity);
            setBankAccount(data.bankAccount || '');
            setStatus(data.status);
            setEmploymentType(data.employmentType);
            setOriginalEmploymentType(data.employmentType);
            setVersion(data.version);
            setIsBusDriver(data.jobTitle === 'autóbuszvezető');
            setClassification(data.driverAttribute?.classification || 'BUS_LOCAL');
            setKeycloakUsername(data.keycloakUsername || '');
            setLoading(false);
        };

        if (employeeId !== undefined && !loading) {
            getEmployeeData();
        }
    }, [employeeId]);

    const handleSave = async () => {
        const cards: PersonalCard[] = [
            {
                type: CardType.ID_CARD,
                number: idCardNumber,
                validTo: formatDateToYyyyMMdd(idCardValidTo),
            },
            {
                type: CardType.ADDRESS_CARD,
                number: addressCardNumber,
                validTo: '',
            },
            {
                type: CardType.DRIVING_LICENCE_CARD,
                number: drivingLicenceNumber,
                validTo: formatDateToYyyyMMdd(drivingLicenceNumberValidTo),
            },
            {
                type: CardType.DRIVER_QUALIFICATION_LICENCE_CARD,
                number: drivingQualificationLicenceNumber,
                validTo: formatDateToYyyyMMdd(drivingQualificationLicenceNumberValidTo),
            },
            {
                type: CardType.TACHOGRAPH_CARD,
                number: tachographCardNumber,
                validTo: formatDateToYyyyMMdd(tachographCardNumberValidTo),
            },
        ];

        const response = await fetchResource(`${API_ENDPOINT}/employee/${employeeId || ''}`, {
            method: employeeId ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                version,
                firstName,
                lastName,
                nickName,
                birthName,
                email,
                phoneNumber,
                dateOfBirth: formatDateToYyyyMMdd(dateOfBirth),
                placeOfBirth,
                permanentAddress,
                temporaryAddress,
                mothersName,
                taxNumber,
                healthCareNumber,
                medicalFitnessValidTo: formatDateToYyyyMMdd(medicalFitnessValidTo),
                companyDoctor,
                hasAnnuity,
                retired,
                cards,
                conductors: Object.values(conductors),
                status,
                employmentType,
                bankAccount: bankAccount.length > 0 ? bankAccount : null,
                driverAttribute: isBusDriver
                    ? {
                          iButton,
                          siteAddress,
                          classification,
                      }
                    : null,
            }),
        });
        if (!response.ok) {
            const body = await response.json();

            const errorMessage = (
                <>
                    <Typography paragraph m={0} p={0}>
                        {body.message || response.statusText}
                    </Typography>
                    {body.errors && (
                        <List>
                            {Object.keys(body.errors).map((errorKey: string, i) => (
                                <ListItem sx={{ p: 0, m: 0 }} key={i}>
                                    <ListItemText primary={body.errors[errorKey]} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </>
            );
            showError(errorMessage);
            return;
        }

        if (response.status === 200 || response.status === 201) {
            navigate(`/employee/`);
            return;
        }

        showError(
            <Typography paragraph p={0} m={0}>
                Nem sikerült a mentés
            </Typography>
        );
    };
    const getMedicalFitnessHelperText = () => {
        if (medicalFitnessValidTo == null) {
            return 'Hiányzó érvényességi idő';
        }

        if (medicalFitnessValidTo.getTime() < today.getTime()) {
            return 'Lejárt érvényesség';
        }
        return '';
    };

    if (!user) {
        return null;
    }

    return (
        <SecuredLayout>
            <Grid container px={3} pt={1}>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/'>
                            <GroupOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Állomány
                        </Link>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} color='inherit'>
                            {employeeId === undefined ? (
                                <>
                                    <PersonAddAltOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                                    Új dolgozó
                                </>
                            ) : (
                                <>
                                    <PermIdentityOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                                    {`${lastName} ${firstName}`}
                                </>
                            )}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Box component='form'>
                <Grid container>
                    {snackBar()}
                    <Grid xs={12} lg={8}>
                        <Card variant={'elevation'} sx={{ px: 1, py: 3, m: 2 }}>
                            <Grid container px={3} pb={2} spacing={2}>
                                <Typography variant={'h4'}>Törzsadatok</Typography>
                            </Grid>
                            <Grid container px={2} spacing={1} py={0}>
                                <Grid xs={12} md={6} lg={2}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant='standard'
                                        label={'Vezetéknév'}
                                        onChange={e => setLastName(e.target.value)}
                                        {...fieldValidator(lastName, InputField.FIRST_NAME)}
                                        value={lastName}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} lg={2}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant='standard'
                                        label={'Keresztnév'}
                                        onChange={e => setFirstName(e.target.value)}
                                        {...fieldValidator(firstName, InputField.LAST_NAME)}
                                        value={firstName}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} lg={2}>
                                    <TextField fullWidth variant='standard' label={'Egyedi név'} onChange={e => setNickName(e.target.value)} value={nickName} />
                                </Grid>
                                <Grid xs={12} md={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant='standard'
                                        label={'Születési név'}
                                        onChange={e => setBirthName(e.target.value)}
                                        value={birthName}
                                    />
                                </Grid>
                                {isBusDriver && (
                                    <Grid xs={12} md={6} lg={2} mt={'-6px'} lgOffset={1}>
                                        <InputLabel id='classification-label'>
                                            <small>Besorolás</small>
                                        </InputLabel>
                                        <Select
                                            variant='standard'
                                            value={classification}
                                            fullWidth
                                            onChange={e => setClassification(e.target.value as keyof typeof DriverClassification)}>
                                            {Object.entries(DriverClassification).map(([key, value]) => (
                                                <MenuItem key={key} value={key}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container px={2} spacing={2}>
                                <Grid xs={12} md={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        required
                                        label={'Születési hely'}
                                        {...fieldValidator(placeOfBirth, InputField.CITY)}
                                        onChange={e => setPlaceOfBirth(e.target.value)}
                                        value={placeOfBirth}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} lg={3}>
                                    <DesktopDatePicker
                                        label='Születési idő'
                                        format='yyyy.MM.dd.'
                                        value={dateOfBirth}
                                        onChange={date => setDateOfBirth(date)}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        required
                                        label={'Anyja neve'}
                                        {...fieldValidator(mothersName, InputField.MOTHERS_NAME)}
                                        onChange={e => setMothersName(e.target.value)}
                                        value={mothersName}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} lg={3}>
                                    <DesktopDatePicker
                                        label='Egészségügyi alkalmassági érv.'
                                        format='yyyy.MM.dd.'
                                        value={medicalFitnessValidTo}
                                        onChange={date => setMedicalFitnessValidTo(date)}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                                helperText: getMedicalFitnessHelperText(),
                                                error: medicalFitnessValidTo == null || today.getTime() > medicalFitnessValidTo.getTime(),
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container px={2} spacing={2}>
                                <Grid xs={12} md={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        required
                                        label={'Adóazonosító jel'}
                                        {...fieldValidator(taxNumber, InputField.TAX_NUMBER)}
                                        onChange={e => setTaxNumber(e.target.value)}
                                        value={taxNumber}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        required
                                        label={'TAJ szám'}
                                        {...fieldValidator(healthCareNumber, InputField.HEALTH_CARE_NUMBER)}
                                        onChange={e => setHealthCareNumber(e.target.value)}
                                        value={healthCareNumber}
                                    />
                                </Grid>
                                <Grid xs={12} lg={6}>
                                    <Autocomplete
                                        options={companyDoctors.map(doctor => doctor.name)}
                                        onChange={(e, value) => setCompanyDoctor((value as string) || null)}
                                        value={companyDoctor}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                error={companyDoctor == null}
                                                helperText={companyDoctor == null ? 'Hiányzó adat' : ''}
                                                variant='standard'
                                                label='Üzemorvos'
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container px={2} spacing={2}>
                                <Grid xs={12} md={6} lg={2}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Telefonszám'}
                                        onChange={e => setPhoneNumber(e.target.value)}
                                        {...fieldValidator(phoneNumber, InputField.PHONE_NUMBER)}
                                        value={phoneNumber}
                                    />
                                </Grid>
                                <Grid xs={12} md={6} lg={4}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'E-mail'}
                                        onChange={e => setEmail(e.target.value)}
                                        {...fieldValidator(email, InputField.EMAIL)}
                                        value={email}
                                    />
                                </Grid>

                                <Grid xs={12} md={6} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Bankszámlaszám'}
                                        onChange={e => setBankAccount(e.target.value)}
                                        {...fieldValidator(bankAccount, InputField.BANK_ACCOUNT)}
                                        value={bankAccount}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container px={2} spacing={2}>
                                <Grid xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Állandó lakcím'}
                                        onChange={e => setPermanentAddress(e.target.value)}
                                        {...fieldValidator(permanentAddress, InputField.PERMANENT_ADDRESS)}
                                        value={permanentAddress}
                                    />
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Ideiglenes lakcím'}
                                        onChange={e => setTemporaryAddress(e.target.value)}
                                        value={temporaryAddress}
                                    />
                                </Grid>
                            </Grid>
                            {isBusDriver && (
                                <Grid container px={2} spacing={2}>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            variant='standard'
                                            label={'Telephely címe'}
                                            onChange={e => setSiteAddress(e.target.value)}
                                            {...fieldValidator(permanentAddress, InputField.PERMANENT_ADDRESS)}
                                            value={siteAddress}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            variant='standard'
                                            label={'iButton azonosító'}
                                            onChange={e => setIButton(e.target.value.toUpperCase())}
                                            {...fieldValidator(iButton, InputField.IBUTTON)}
                                            value={iButton}
                                        />
                                    </Grid>
                                    {keycloakUsername && (
                                        <Grid xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                variant='standard'
                                                label={'Keycloak felhasználónév'}
                                                defaultValue={keycloakUsername}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                            <Grid container px={2} spacing={5}>
                                <Grid xs={12} sm={6} md={'auto'}>
                                    <FormControl required sx={{ mt: -1 }}>
                                        <FormLabel>Járadékban részesül?</FormLabel>
                                        <RadioGroup row value={hasAnnuity} onChange={(e, value) => setHasAnnuity(value === 'true')}>
                                            <FormControlLabel control={<Radio value={false} />} label={'nem'} value={false} />
                                            <FormControlLabel control={<Radio value={true} />} label={'igen'} value={true} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} sm={6} md={'auto'}>
                                    <FormControl required sx={{ mt: -1 }}>
                                        <FormLabel>Nyugdíjas?</FormLabel>
                                        <RadioGroup row value={retired} onChange={(e, value) => setRetired(value === 'true')}>
                                            <FormControlLabel control={<Radio value={false} />} label={'nem'} value={false} />
                                            <FormControlLabel control={<Radio value={true} />} label={'igen'} value={true} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} sm={6} md={'auto'}>
                                    <FormGroup>
                                        <FormLabel sx={{ m: -1 }}>Vezénylő</FormLabel>
                                        <FormControl required sx={{ mt: 1, ml: -1, flexDirection: 'row' }}>
                                            {Object.keys(ConductorType).map(key => (
                                                <FormControlLabel
                                                    key={key}
                                                    control={
                                                        <Checkbox
                                                            checked={conductors.includes(key as ConductorType)}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    setConductors([...conductors, key as ConductorType]);
                                                                } else {
                                                                    setConductors(conductors.filter(conductor => conductor !== key));
                                                                }
                                                            }}
                                                            name={key}
                                                        />
                                                    }
                                                    label={ConductorType[key as keyof typeof ConductorType]}
                                                />
                                            ))}
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid xs={12} sm={6} md={'auto'}>
                                    <FormControl required sx={{ mt: -1 }}>
                                        <FormLabel>Foglalkoztatás formája</FormLabel>
                                        <RadioGroup
                                            row
                                            value={employmentType}
                                            onChange={(e, value) => {
                                                e.persist();
                                                setEmploymentType(value as keyof typeof EmploymentType);
                                            }}>
                                            {Object.keys(EmploymentType).map(key => (
                                                <FormControlLabel
                                                    control={<Radio value={key as keyof typeof EmploymentType} />}
                                                    label={EmploymentType[key as keyof typeof EmploymentType]}
                                                    value={EmploymentType[key as keyof typeof EmploymentType]}
                                                    key={key}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} sm={6} md={'auto'}>
                                    <FormControl sx={{ mt: -1 }}>
                                        <FormLabel>Státusz</FormLabel>
                                        <RadioGroup row value={status} onChange={(e, value) => setStatus(value as keyof typeof StatusType)}>
                                            <FormControlLabel
                                                control={<Radio value={getEnumKeyByValue(StatusType, StatusType.ACTIVE)} />}
                                                label={StatusType.ACTIVE}
                                                value={getEnumKeyByValue(StatusType, StatusType.ACTIVE)}
                                            />
                                            <FormControlLabel
                                                control={<Radio value={getEnumKeyByValue(StatusType, StatusType.INACTIVE)} />}
                                                label={StatusType.INACTIVE}
                                                value={getEnumKeyByValue(StatusType, StatusType.INACTIVE)}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid xs={12} lg={4}>
                        <Card variant={'elevation'} sx={{ backgroundColor: '#eee', px: 1, py: 3, m: 2 }}>
                            <Grid container px={3} pb={2} spacing={2}>
                                <Typography variant={'h4'}>Okmányok</Typography>
                            </Grid>
                            <Grid container px={2} spacing={1}>
                                <Grid xs={12} sm={3} lg={6}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Szem. az. ig.'}
                                        onChange={e => setIdCardNumber(e.target.value)}
                                        {...fieldValidator(idCardNumber, InputField.ID_CARD_NUMBER)}
                                        value={idCardNumber}
                                    />
                                </Grid>
                                <Grid xs={12} sm={3} lg={6}>
                                    <DesktopDatePicker
                                        label='Érvényes'
                                        format='yyyy.MM.dd.'
                                        value={idCardValidTo}
                                        onChange={date => setIdCardValidTo(date)}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={3} lg={6}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Lakcímkártya'}
                                        onChange={e => setAddressCardNumber(e.target.value)}
                                        {...fieldValidator(addressCardNumber, InputField.ADDRESS_CARD_NUMBER)}
                                        value={addressCardNumber}
                                    />
                                </Grid>
                                <Grid xs={12} sm={3} lg={6} />
                                <Grid xs={12} sm={3} lg={6}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Jogosítvány'}
                                        onChange={e => setDrivingLicenceNumber(e.target.value)}
                                        {...(employeeId === undefined || isBusDriver
                                            ? fieldValidator(drivingLicenceNumber, InputField.DRIVING_LICENCE_NUMBER)
                                            : null)}
                                        value={drivingLicenceNumber}
                                    />
                                </Grid>
                                <Grid xs={12} sm={3} lg={6}>
                                    <DesktopDatePicker
                                        label='Érvényes'
                                        format='yyyy.MM.dd.'
                                        value={drivingLicenceNumberValidTo}
                                        onChange={date => setDrivingLicenceNumberValidTo(date)}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={3} lg={6}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'GKI kártya'}
                                        onChange={e => setDrivingQualificationLicenceNumber(e.target.value)}
                                        {...(employeeId === undefined || isBusDriver
                                            ? fieldValidator(drivingQualificationLicenceNumber, InputField.DRIVING_QUALIFICATION_LICENCE_NUMBER)
                                            : null)}
                                        value={drivingQualificationLicenceNumber}
                                    />
                                </Grid>
                                <Grid xs={12} sm={3} lg={6}>
                                    <DesktopDatePicker
                                        label='Érvényes'
                                        format='yyyy.MM.dd.'
                                        value={drivingQualificationLicenceNumberValidTo}
                                        onChange={date => setDrivingQualificationLicenceNumberValidTo(date)}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container px={2} spacing={2}>
                                <Grid xs={12} sm={3} lg={6}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Tachográf kártya'}
                                        onChange={e => setTachographCardNumber(e.target.value)}
                                        {...(employeeId === undefined || isBusDriver
                                            ? fieldValidator(tachographCardNumber, InputField.TACHOGRAP_CARD_NUMBER)
                                            : null)}
                                        value={tachographCardNumber}
                                    />
                                </Grid>
                                <Grid xs={12} sm={3} lg={6}>
                                    <DesktopDatePicker
                                        label='Érvényes'
                                        format='yyyy.MM.dd.'
                                        value={tachographCardNumberValidTo}
                                        onChange={date => setTachographCardNumberValidTo(date)}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'end'} spacing={1} mx={1} pt={3} pb={2}>
                    {(user.hasRole(Role.BR_HR) || user.hasRole(Role.BR_HR_HELYETTES)) && (
                        <Button sx={{ mx: 1, borderRadius: 50 }} variant={'contained'} color={'primary'} onClick={handleSave} endIcon={<LibraryAddOutlined />}>
                            mentés
                        </Button>
                    )}

                    {originalEmploymentType !== getEnumKeyByValue(EmploymentType, EmploymentType.PERMANENT) &&
                        (user.hasRole(Role.BR_HR) || user.hasRole(Role.BR_HR_HELYETTES) || user.hasRole(Role.VEZENYLO) || user.hasRole(Role.ADMIN)) && (
                            <Button
                                sx={{ mx: 1, borderRadius: 50 }}
                                variant={'contained'}
                                color={'warning'}
                                onClick={e => {
                                    e.preventDefault();
                                    navigate(`/employee/${employeeId}/notification`, {
                                        state: {
                                            employeeId,
                                            employeeName: `${lastName} ${firstName}`,
                                            taxNumber,
                                            healthCareNumber,
                                            employee,
                                        },
                                    });
                                }}
                                startIcon={<BusinessOutlinedIcon />}>
                                alkalmi bejelentések
                            </Button>
                        )}
                    {!!employeeId ? (
                        <>
                            {(user.hasRole(Role.BR_HR) || user.hasRole(Role.ADMIN)) && (
                                <Button
                                    sx={{ mx: 1, borderRadius: 50 }}
                                    variant={'contained'}
                                    color={'secondary'}
                                    onClick={() =>
                                        navigate(`/employee/${employeeId}/contract`, {
                                            state: {
                                                employeeName: `${lastName} ${firstName}`,
                                            },
                                        })
                                    }
                                    startIcon={<ArticleIcon />}>
                                    szerződések
                                </Button>
                            )}
                            {isBusDriver && (user.hasRole(Role.BR_HR) || user.hasRole(Role.BR_HR_HELYETTES) || user.hasRole(Role.ADMIN)) ? (
                                <WarningModal />
                            ) : null}
                            {user.hasRole(Role.BR_HR) || user.hasRole(Role.BR_HR_HELYETTES) || user.hasRole(Role.ADMIN) ? (
                                <Button
                                    sx={{ mx: 1, borderRadius: 50 }}
                                    variant={'contained'}
                                    color={'success'}
                                    onClick={() =>
                                        navigate(`/employee/${employeeId}/document`, {
                                            state: {
                                                employeeName: `${lastName} ${firstName}`,
                                            },
                                        })
                                    }
                                    startIcon={<CloudUploadOutlined />}>
                                    feltöltött dokumentumok
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                    {isBusDriver && !keycloakUsername && (
                        <>
                            <KeycloakConfirmModal
                                open={keycloakModalOpen}
                                onClose={() => setKeycloakModalOpen(false)}
                                employeeId={employeeId}
                                setKeycloakUsername={setKeycloakUsername}
                                email={email}
                            />
                            <Button
                                sx={{ mx: 1, borderRadius: 50 }}
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => setKeycloakModalOpen(true)}
                                startIcon={<KeyIcon />}>
                                keyCloak felhasználó létrehozása
                            </Button>
                        </>
                    )}
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        color={'info'}
                        onClick={() => navigate(`/employee?${employeeListSearch.toString()}`)}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        vissza
                    </Button>
                </Grid>
            </Box>
        </SecuredLayout>
    );
};

export { EmployeeDetailsPage };

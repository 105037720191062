import {DriverClassification} from './document';

export type Employee = {
    uuid: string;
    firstName: string;
    lastName: string;
    nickName?: string;
    legalName?: string;
    birthName: string;
    mothersName?: string;
    medicalFitnessValidTo?: string;
    permanentAddress?: string;
    temporaryAddress?: string;
    placeOfBirth?: string;
    dateOfBirth?: string;
    taxNumber?: string;
    healthCareNumber?: string;
    phoneNumber?: string;
    email?: string;
    cards: Card[];
    idCard?: Card;
    addressCard?: Card;
    drivingLicenceCard?: Card;
    drivingQualificationLicenceCard?: Card;
    tachographCard?: Card;
    contracts: Contract[];
    conductors: ConductorType[];
    companyDoctor?: CompanyDoctor;
    retired: boolean;
    hasAnnuity: boolean;
    bankAccount?: string;
    status: keyof typeof StatusType;
    employmentType: keyof typeof EmploymentType;
    version: number;
    jobTitle: string;
    keycloakUsername: string;
    origin?: Contract;
    driverAttribute?: DriverAttribute;
};

export type DriverAttribute = {
    ibutton: string;
    siteAddress: string;
    classification: keyof typeof DriverClassification;
};

export type EmployeeNotificationRequiredDetails = {
    legalName: string;
    taxNumber: string;
    healthCareNumber: string;
};

export type EmployeeListItem = {
    uuid: string;
    name: string;
    legalName: string;
    jobTitle: string;
    phoneNumber: string;
    email: string;
    dateOfBirth: string;
    medicalFitnessValidTo: string;
    retired: boolean;
    numberOfExpiringCards: number;
    numberOfExpiredCards: number;
    numberOfExpiredDriverCards: number;
    employmentType: keyof typeof EmploymentType;
    status: string;
    idCardNumber?: string;
    drivingLicenceCardNumber?: string;
    taxNumber: string;
    healthCareNumber: string;
    drivingLicenceValidTo?: string;
    drivingQualificationLicenceValidTo?: string;
    tachographCardValidTo?: string;
};

export enum StatusType {
    ACTIVE = 'aktív',
    INACTIVE = 'inaktív',
}

export enum EmployerCompany {
    PANNON_BUSZ_RENT = 'Pannon Busz-Rent Kft',
    HR_MONTAGE = 'HR-Montage Kft',
    SERVICE_FLOW = 'Service Flow Kft',
}

export enum NotificationType {
    U = 'bejelentés',
    T = 'kijelentés',
}

export enum WarningNotificationType {
    EMAIL = 'E-mail',
    PHONE = 'Telefon',
    SMS = 'Sms',
    POST = 'Posta',
}

export const enum CardType {
    ID_CARD = 'ID_CARD',
    ADDRESS_CARD = 'ADDRESS_CARD',
    DRIVING_LICENCE_CARD = 'DRIVING_LICENCE_CARD',
    DRIVER_QUALIFICATION_LICENCE_CARD = 'DRIVER_QUALIFICATION_LICENCE_CARD',
    TACHOGRAPH_CARD = 'TACHOGRAPH_CARD'
}

export type Card = {
    type: CardType;
    number: string;
    validTo: string;
};

export type JobTitle = {
    id?: number;
    name: string;
    value: string;
    description?: string;
};

export type CompanyDoctor = {
    id: string;
    name: string;
    city: string;
};

export type ListResponse<T> = {
    content: T[];
    numberOfElements: number;
};

export type ConsecutiveDaysType = {
    company: keyof typeof EmployerCompany;
    count: number;
};

export type CompanyStatisticType = {
    company: keyof typeof EmployerCompany;
    yearly: number;
    monthly: number;
};

export interface EFOStatiticsType extends Record<keyof typeof EmployerCompany, number> {}

export type EFOStatisticsTimeIntervalType = {
    YEARLY: EFOStatiticsType;
    MONTHLY: EFOStatiticsType;
};

export type EFOStatistics = {
    statistics: CompanyStatisticType[];
    consecutiveDays: ConsecutiveDaysType;
};

export enum EmploymentType {
    PERMANENT = 'állományos',
    SIMPLE_EMPLOYMENT = 'egyszerű foglalkoztatás',
    CONTRACTOR = 'külsős',
}

export enum WageType {
    HOURLY = 'órabér',
    DAILY = 'napibér',
    MONTHLY = 'havibér',
}

export type Contract = {
    id: number;
    uuid: string;
    from: string;
    to: string;
    jobTitle: JobTitle;
    employmentType: keyof typeof EmploymentType;
    wage: number;
    wageType: keyof typeof WageType;
    dailyWorkHours: number;
    hasAnnuity: boolean;
    isNorthConductor: boolean;
    isSouthConductor: boolean;
    signedAt: string;
    createdOn: string;
    documents: UploadedContractDocumentDetails[];
    hasOrigin: boolean;
    followUpContract?: Contract;
};

export enum ContractDocumentType {
    OSSZESITETT_DOKUMENTUMOK = 'Az összes szerződés letöltése egyben',
    MUNKASZERZODES = 'Munkaszerződés',
    ELOZETES_TAJEKOZTATO = 'Előzetes tájékoztató',
    KULON_MEGALLAPODAS = 'Külön megállapodás',
    ADATKEZELESI_NYILATKOZAT = 'Adatkezelési nyilatkozat',
    MUNKAKORI_LEIRAS = 'Munkaköri leírás',
    SZAKAGI_TAJEKOZTATO = 'Szakági tájékoztató',
    TAJEKOZTATO = 'Tájékoztató',
    UGYVEZETOI_UTASITAS = '1/2010 Ügyvezetői utasítás',
    BANKSZAMLA_NYILATKOZAT = 'Bankszámla nyilatkozat',
    SZERZODESMODOSITAS = 'Szerződésmódosítás',
}

export type UploadedContractDocumentDetails = {
    link: string;
    documentType: keyof ContractDocumentType;
    acceptOn?: string;
};

export interface UploadedDocumentDictionary extends Record<ContractDocumentType, UploadedContractDocumentDetails> {}

export enum ConductorType {
    NORTH = 'északi',
    SOUTH = 'déli',
    SZEGED = 'szegedi',
}

export type FilterType = {
    value: string;
    label: string;
};

export type EmploymentRecord = {
    id: number;
    workday: string;
    company: string;
    notifiedAt: string;
    notifiedBy: string;
    notificationType: string;
    success: boolean;
    confirmedAt: string;
    wage?: number;
};

export type EmploymentWeekdaySummery = {
    id: number;
    company: string;
    confirmedAt: string;
    createdAt: string;
    createdBy: string;
    date: string;
    notificationType: string;
    healthCareNumber: string;
    taxIdNumber: string;
    wage: number;
};

export type NotificationStat = {
    company: string;
    yearlyNotifications: number;
    monthlyNotifications: number;
};

export type DocumentListItem = {
    uuid: string;
    filename: string;
    type: string;
    description?: string;
    uploadedAt: string;
    uploadedBy: string;
    link: string;
};

export type AwaitingNotificationStatisticsResponse = {
    countOfUnsentNotifications: number;
    countOfUnsentWorkdays: number;
};

export type ExpiracyWarningItem = {
    id: number;
    type: string;
    employee: Employee;
    content: string;
    sentAt: string;
};

export type WorkdayCompany = {
    workday: string;
    company: string;
};

export type EmployeeWorkday = {
    [key: string]: WorkdayCompany[];
};

export type ExpiringDriverCertificateItem = { 
    uuid: string;
    firstName: string;
    lastName: string;
    type: string;
    validTo: string;
};

export type ExpiringDocumentsResponse = {
    content: ExpiringDriverCertificateItem[];
};

export type ExpiringDriverCertificateGridItem = {
    id: string;
    name: string;
    documentType: string;
    expirationDate: string;
};



